import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _138c541e = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _48123484 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _01c79ac9 = () => interopDefault(import('../pages/e-learning/index.vue' /* webpackChunkName: "pages/e-learning/index" */))
const _87ed5ba0 = () => interopDefault(import('../pages/for-distributors.vue' /* webpackChunkName: "pages/for-distributors" */))
const _5135d438 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _4c1b16ea = () => interopDefault(import('../pages/get-your-box/index.vue' /* webpackChunkName: "pages/get-your-box/index" */))
const _74c2f7e9 = () => interopDefault(import('../pages/impersonate.vue' /* webpackChunkName: "pages/impersonate" */))
const _7e8a0d81 = () => interopDefault(import('../pages/pickup-request/index.vue' /* webpackChunkName: "pages/pickup-request/index" */))
const _49d23142 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _3e96b719 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _71a00388 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _cdffaab6 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _5c6432d7 = () => interopDefault(import('../pages/user-deleted.vue' /* webpackChunkName: "pages/user-deleted" */))
const _02734930 = () => interopDefault(import('../pages/vessels/index.vue' /* webpackChunkName: "pages/vessels/index" */))
const _01f5a55d = () => interopDefault(import('../pages/webinar/index.vue' /* webpackChunkName: "pages/webinar/index" */))
const _c55b6ae4 = () => interopDefault(import('../pages/documents/privacy-policy.vue' /* webpackChunkName: "pages/documents/privacy-policy" */))
const _46131f2b = () => interopDefault(import('../pages/documents/terms-and-conditions.vue' /* webpackChunkName: "pages/documents/terms-and-conditions" */))
const _669ae50e = () => interopDefault(import('../pages/email/thank-you.vue' /* webpackChunkName: "pages/email/thank-you" */))
const _398a5c19 = () => interopDefault(import('../pages/registration/finish.vue' /* webpackChunkName: "pages/registration/finish" */))
const _46e41e25 = () => interopDefault(import('../pages/registration/personal-information.vue' /* webpackChunkName: "pages/registration/personal-information" */))
const _ea5e9134 = () => interopDefault(import('../pages/registration/why-do-you-sign-up.vue' /* webpackChunkName: "pages/registration/why-do-you-sign-up" */))
const _45751ec1 = () => interopDefault(import('../pages/vessels/add-new-vessel.vue' /* webpackChunkName: "pages/vessels/add-new-vessel" */))
const _57799918 = () => interopDefault(import('../pages/webinar/finish.vue' /* webpackChunkName: "pages/webinar/finish" */))
const _6d661afc = () => interopDefault(import('../pages/course/join/success.vue' /* webpackChunkName: "pages/course/join/success" */))
const _27c38fc2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6dc4c91c = () => interopDefault(import('../pages/course/join/_uuid/index.vue' /* webpackChunkName: "pages/course/join/_uuid/index" */))
const _5a5b77ea = () => interopDefault(import('../pages/forgot-password/form/_token.vue' /* webpackChunkName: "pages/forgot-password/form/_token" */))
const _25c81fba = () => interopDefault(import('../pages/registration/verify/_token.vue' /* webpackChunkName: "pages/registration/verify/_token" */))
const _655968b4 = () => interopDefault(import('../pages/course/join/_uuid/connect.vue' /* webpackChunkName: "pages/course/join/_uuid/connect" */))
const _d54b4ac2 = () => interopDefault(import('../pages/product-details/_uuid.vue' /* webpackChunkName: "pages/product-details/_uuid" */))
const _b6ec264a = () => interopDefault(import('../pages/register-vessel/_uuid.vue' /* webpackChunkName: "pages/register-vessel/_uuid" */))
const _678e0d66 = () => interopDefault(import('../pages/vessel-results/_id/index.vue' /* webpackChunkName: "pages/vessel-results/_id/index" */))
const _babb1668 = () => interopDefault(import('../pages/quiz/_quiz_id/result.vue' /* webpackChunkName: "pages/quiz/_quiz_id/result" */))
const _5e133683 = () => interopDefault(import('../pages/quiz/_quiz_id/start.vue' /* webpackChunkName: "pages/quiz/_quiz_id/start" */))
const _658f257a = () => interopDefault(import('../pages/vessel-results/_id/3d-image-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/3d-image-of-your-vessel" */))
const _70fbd306 = () => interopDefault(import('../pages/vessel-results/_id/clinical-results-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/clinical-results-of-your-vessel" */))
const _198242a2 = () => interopDefault(import('../pages/vessel-results/_id/community-evaluation-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/community-evaluation-of-your-vessel" */))
const _179a5dfd = () => interopDefault(import('../pages/vessel-results/_id/details.vue' /* webpackChunkName: "pages/vessel-results/_id/details" */))
const _769d64bd = () => interopDefault(import('../pages/vessel-results/_id/expert-evaluation-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/expert-evaluation-of-your-vessel" */))
const _59d352e8 = () => interopDefault(import('../pages/vessel-results/_id/give-a-feedback.vue' /* webpackChunkName: "pages/vessel-results/_id/give-a-feedback" */))
const _557edcec = () => interopDefault(import('../pages/vessel-results/_id/score-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/score-of-your-vessel" */))
const _0460850a = () => interopDefault(import('../pages/vessel-results/_id/steps-summary.vue' /* webpackChunkName: "pages/vessel-results/_id/steps-summary" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _138c541e,
    name: "about-us"
  }, {
    path: "/dashboard",
    component: _48123484,
    name: "dashboard"
  }, {
    path: "/e-learning",
    component: _01c79ac9,
    name: "e-learning"
  }, {
    path: "/for-distributors",
    component: _87ed5ba0,
    name: "for-distributors"
  }, {
    path: "/forgot-password",
    component: _5135d438,
    name: "forgot-password"
  }, {
    path: "/get-your-box",
    component: _4c1b16ea,
    name: "get-your-box"
  }, {
    path: "/impersonate",
    component: _74c2f7e9,
    name: "impersonate"
  }, {
    path: "/pickup-request",
    component: _7e8a0d81,
    name: "pickup-request"
  }, {
    path: "/product",
    component: _49d23142,
    name: "product"
  }, {
    path: "/profile",
    component: _3e96b719,
    name: "profile"
  }, {
    path: "/registration",
    component: _71a00388,
    name: "registration"
  }, {
    path: "/sign-in",
    component: _cdffaab6,
    name: "sign-in"
  }, {
    path: "/user-deleted",
    component: _5c6432d7,
    name: "user-deleted"
  }, {
    path: "/vessels",
    component: _02734930,
    name: "vessels"
  }, {
    path: "/webinar",
    component: _01f5a55d,
    name: "webinar"
  }, {
    path: "/documents/privacy-policy",
    component: _c55b6ae4,
    name: "documents-privacy-policy"
  }, {
    path: "/documents/terms-and-conditions",
    component: _46131f2b,
    name: "documents-terms-and-conditions"
  }, {
    path: "/email/thank-you",
    component: _669ae50e,
    name: "email-thank-you"
  }, {
    path: "/registration/finish",
    component: _398a5c19,
    name: "registration-finish"
  }, {
    path: "/registration/personal-information",
    component: _46e41e25,
    name: "registration-personal-information"
  }, {
    path: "/registration/why-do-you-sign-up",
    component: _ea5e9134,
    name: "registration-why-do-you-sign-up"
  }, {
    path: "/vessels/add-new-vessel",
    component: _45751ec1,
    name: "vessels-add-new-vessel"
  }, {
    path: "/webinar/finish",
    component: _57799918,
    name: "webinar-finish"
  }, {
    path: "/course/join/success",
    component: _6d661afc,
    name: "course-join-success"
  }, {
    path: "/",
    component: _27c38fc2,
    name: "index"
  }, {
    path: "/course/join/:uuid",
    component: _6dc4c91c,
    name: "course-join-uuid"
  }, {
    path: "/forgot-password/form/:token",
    component: _5a5b77ea,
    name: "forgot-password-form-token"
  }, {
    path: "/registration/verify/:token?",
    component: _25c81fba,
    name: "registration-verify-token"
  }, {
    path: "/course/join/:uuid?/connect",
    component: _655968b4,
    name: "course-join-uuid-connect"
  }, {
    path: "/product-details/:uuid?",
    component: _d54b4ac2,
    name: "product-details-uuid"
  }, {
    path: "/register-vessel/:uuid?",
    component: _b6ec264a,
    name: "register-vessel-uuid"
  }, {
    path: "/vessel-results/:id",
    component: _678e0d66,
    name: "vessel-results-id"
  }, {
    path: "/quiz/:quiz_id?/result",
    component: _babb1668,
    name: "quiz-quiz_id-result"
  }, {
    path: "/quiz/:quiz_id?/start",
    component: _5e133683,
    name: "quiz-quiz_id-start"
  }, {
    path: "/vessel-results/:id?/3d-image-of-your-vessel",
    component: _658f257a,
    name: "vessel-results-id-3d-image-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/clinical-results-of-your-vessel",
    component: _70fbd306,
    name: "vessel-results-id-clinical-results-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/community-evaluation-of-your-vessel",
    component: _198242a2,
    name: "vessel-results-id-community-evaluation-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/details",
    component: _179a5dfd,
    name: "vessel-results-id-details"
  }, {
    path: "/vessel-results/:id?/expert-evaluation-of-your-vessel",
    component: _769d64bd,
    name: "vessel-results-id-expert-evaluation-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/give-a-feedback",
    component: _59d352e8,
    name: "vessel-results-id-give-a-feedback"
  }, {
    path: "/vessel-results/:id?/score-of-your-vessel",
    component: _557edcec,
    name: "vessel-results-id-score-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/steps-summary",
    component: _0460850a,
    name: "vessel-results-id-steps-summary"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
